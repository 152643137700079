$colorMain: #0080FF;

body {
    position: relative;
    color: #b0b0b0;
    margin: 0;
    padding: 0;
    background-color: #000000;
    font-family: 'Roboto Mono', monospace;
    overflow-x: hidden;
}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

header {
    width: 100vw;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 40px;
    z-index: 10;
}

a {
    color: inherit;
}

.color-main {
    color: $colorMain;
}

.margin-auto {
    margin: 0 auto;
}

.text-center {
    text-align: center;
}

section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: relative;
    z-index: 10;
}

h2 {
    font-style: italic;
    font-size: 20px;
    margin-bottom: 20px;
}

h4 {
    margin: 10px 0 15px 0;
    font-style: italic;
    font-size: 16px;
    color: $colorMain;
    font-weight: 300;
}

.skills {
    &__list {
        list-style: none;
        padding: 0;
        margin: 0 0 20px 0;
        display: flex;
        flex-wrap: wrap;

        li {
            display: inline-flex;
            align-content: center;
            align-items: center;
            line-height: 25px;
            margin-right: 15px;
            margin-bottom: 5px;
        }

        &-icon {
            width: 20px;
            height: auto;
            margin-right: 5px;
        }

        &-name {
            vertical-align: middle;
        }
    }
}

#particles {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.header {
    &__name {
        z-index: 10;
        position: relative;
        padding: 10px 25px;
        background-color: rgb(0, 0, 0);
        font-size: 18px;
        text-align: center;
        font-weight: 300;
        margin: 0 auto;
    }

    &__image-container {
        margin: 2em 0;
        text-align: center;
        position: relative;
        z-index: 10;

        & > canvas {
            border-radius: 100%;
        }
    }

    &__image {
        border-radius: 100%;
        width: 200px;
        height: 200px;
    }

    &__typeit {
        line-height: 30px;
        padding: 0 20px;
    }
}

.flex-container {
    width: 600px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
}

.skills, .projects {
    padding: 10px;
    // width: 50%;
}

header, .skills, .projects, .contact {
    transition: opacity 2s;
}

.hidden {
    opacity: 0;
}

.certification {
    color: #ffd700;
    text-decoration: none;
    margin-left: 5px;
    font-size: 12px;
    &:hover {
        color: #d1b200;
    }
}

.projects {
    &__container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        overflow: hidden;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;

        &:first-of-type {
            margin-bottom: 20px;
        }

        &:last-of-type {
            padding-left: 5px;
        }

        .github {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    &__item {
        position: relative;
        width: calc(50% - 10px);
        /* height: 200px; */
        background-color: #000000;
        background-position: center;
        background-size: cover;
        padding: 20px;
        text-decoration: none;
        margin: 5px;

        &:hover &-overlay {
            background-color: rgba(0, 128, 255, 0.6);
        }

        & > :not(&-overlay) {
            z-index: 10;
            position: relative;
        }

        &-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.6);
            -webkit-transition: background-color 0.3s;
            transition: background-color 0.3s;
            z-index: 5;
        }

        &-logo {
            width: 60px;
            height: auto;
        }

        p {
            background-color: rgba(0, 0, 0, 0.9);
            padding: 5px;

            &.small {
                font-size: 10px;
                color: $colorMain;
            }
        }
    }
}

.contact {
    margin-bottom: 50px;
    width: 100%;
    margin-top: 50px;
    padding: 0 10px;

    &__email, &__links {
        
        color: black;
        

        a {
            background-color: $colorMain;
            padding: 5px 10px;
            transition: color 0.5s;
            &:hover {
                color: white;
            }
        }
    }

    &__email {
        margin-bottom: 20px;
    }

    &__links {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
        }
    }
}

@media only screen and (max-width: 1050px) {
    .skills, .projects, .contact {
        width: 100%;
    }

    .flex-container {
        width: 600px;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    .contact {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 650px) {
    .flex-container {
        width: 400px;
    }

    .projects__item {
        h3 {
            font-size: 16px;
        }

        p:not(.small) {
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 430px) {
    .flex-container {
        width: 100%;
        padding: 0 10px;
    }

    .projects__item {
        width: 100%;
    }
}

@media only screen and (max-width: 200px) {
    .header__image {
        width: 100%;
        height: auto;
    }
}